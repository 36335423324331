import React, { useState } from 'react';
import { CardColumns, Col, Row } from 'react-bootstrap';
import useMobileDetect from 'use-mobile-detect-hook';
import './App.css';
import Box from './box';
import higherImage from './images/higher.jpg';
import upsc from './images/upsc.jpg';
import school from './images/school.jpg';
import verboace from './images/verboace.png';
import robot from './images/robot.jpg';
import { Diversity1 } from '@mui/icons-material';
import { Bounce, Fade, Zoom } from 'react-reveal';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function HomeTop() {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const detectMobile = useMobileDetect();

  // Function to handle navbar position
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsNavSticky(true);
    } else {
      setIsNavSticky(false);
    }
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Attach scroll event listener
  window.addEventListener('scroll', handleScroll);

  return (
    <div className="Landing" style={{backgroundColor:"white"}}>
   <div style={{backgroundColor:"white"}} className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
      { detectMobile.isMobile() ?  <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"auto",margin:0,flexDirection:"column",backgroundColor:"white"}}><img src={verboace} style={{width:"auto",height:120}}></img></div>:null}
      <nav  style={{backgroundColor:"white"}} className={`navbar ${isNavOpen ? 'open' : ''}`} >
        <div className="nav-links" style={{marginLeft:10,alignItems:"center",justifyContent:"center",textAlign:"center"}} >
        <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#1E90FF"}} onClick={()=>{}}>Home</Link>
          <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#1E90FF"}} onClick={()=>{}}>Product</Link>
           <Link to="/" className="nav-button" style={{fontWeight:"bold",color:"#1E90FF"}} onClick={()=>{}}>Contact Us</Link>
           <Link to="/terms-conditions" className="nav-button" style={{fontWeight:"bold",color:"#1E90FF"}}  onClick={()=>{}}>Terms</Link>
         </div>
        { detectMobile.isMobile() ? null : <div  className="nav-logo" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",backgroundColor:"white"}}> <img src={verboace} style={{width:"auto",height:120}}></img>
      </div>}
        <div className="nav-links" style={{alignItems:"center",justifyContent:"center",textAlign:"center"}}>
        <Link to="/privacy-policy" className="nav-button" style={{fontWeight:"bold",color:"#1E90FF"}} onClick={()=>{}}>Privacy-Policy</Link>
       {detectMobile.isMobile() ?  <Link to="/refund-policy" className="nav-button" style={{fontWeight:"bold",color:"#1E90FF"}}  onClick={()=>{}}>Refund-Policy</Link>
        : <Link to="/login-student" className="nav-button" style={{fontWeight:"bold",color:"#1E90FF"}}  onClick={()=>{}}>Student Login</Link>}
        </div>
      </nav>
    </div>
  );
}

export default HomeTop;