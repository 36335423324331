import React, { useEffect } from 'react';
import './index.css';
import {useState} from 'react';
import philomath_logo from './images/philomath_logo.png';
import { apiendpoint } from './endpoint';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, connect, useSelector } from 'react-redux';
import { loginSuccess } from './authActions';

function Signup({isLoggedIn}) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const navigate = useHistory();
    const [user, setUser] = useState({"username":"","password":""})
    const[status, setStatus]=useState("");

    const update = (args)=>{
        console.log(args.target.id)
        console.log(args.target.value)
        var property=args.target.id;
        setUser(prev=>({...prev,[property]:args.target.value}))
    }

    useEffect(()=>{
        if(isLoggedIn){
            if(navigate.push){
                navigate.replace('/secret')
            }
        }
      },[isLoggedIn])

    const submit=()=>{
       fetch(apiendpoint.path+'/v1/auth/dummylogin',{
           method:"POST",
           headers:{ 'Content-Type': 'application/json' },
            body:JSON.stringify({
                "username":user.username,
               "password":user.password

            })
       }).then(response=>{return response.json()}).then(data=>{
        console.log(data)
        dispatch(loginSuccess(
            data.accessToken,
            null
            ));
       }).catch(()=>{setStatus("Something went wrong bro")})
    }

    return (
        <div className="Signup-container" style={{minHeight:100+"vh"}}>
            <form>
               
                <div className="Signup"> 
                <img src={philomath_logo} style={{width:50+"px",height:50+"px"}}></img>
                <h2>Signup here</h2>
                <label> Username:</label>
                <input type="text" placeholder="Rahul" value={user.username} id="username" onChange={update}></input>
                <label>Password:</label>
                <input type="password" placeholder="*******" value={user.password} id="password" onChange={update}></input>
                <div style={{display:"flex",flexDirection:"column", marginTop:20}}>
                <input type="button" value="Submit" onClick={submit}></input>
                <label>{status}</label>
                </div>
                </div>
               
            </form>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user
});

export default connect(mapStateToProps)(Signup);