import { Avatar, Button, Card, CircularProgress, Typography, Tabs, Tab } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import verboace from './../images/verboace.png';
import { useQuizContext } from '../quizContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { connect, useDispatch } from 'react-redux';
import '../index.css';
import { apiendpoint } from '../endpoint';
import axios from 'axios';
import MyCard from './myCard';
import ProfileCard from './profileCard';
import ContentLoader from 'react-content-loader';
import { logout } from '../authActions';
import ConversationModal from './ConversationModal';
import ContentCard from './contentCard';
import CustomModal from './customModal';
import Countdown from 'react-countdown';
import rewardAnimation from '../images/timer.json';
import Lottie from 'lottie-react';
import TestResults from './testResults';
import ReadingGame from './readingGame';
import QuoraActivity from './../quora'


const StudyBuddiesScreen = ({isLoggedIn,token,user}) => {
  const [randomUsers, setRandomUsers] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [studyBuddies, setStudyBuddies] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [addingBuddy, setAddingBuddy] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);


  const ShimmerLoader = () => (
    <ContentLoader
        speed={2}
        width={400}
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
    </ContentLoader>
);



  const fetchData = useCallback(async (page = 1, isRefreshing = false) => {
    if (isRefreshing) {
      setRefreshing(true);
    } else {
      setLoading(true);
    }
    
    try {
        setLoading(true)
      const response = await axios.post(
        `${apiendpoint.path}/v1/users/studybuddiesnew`,
        { page },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      const { randomUsers, studyBuddies, totalPages } = response.data;
      setLoading(false)
      if (isRefreshing) {
        setRandomUsers(randomUsers);
        setStudyBuddies(studyBuddies);
        setTotalPages(totalPages);
        setCurrentPage(1);
      } else {
        setRandomUsers(randomUsers);
        setStudyBuddies(studyBuddies);
        setTotalPages(totalPages);
        setCurrentPage(page);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
        setLoading(false)
      if (isRefreshing) {
        setRefreshing(false);
      } else {
        setLoading(false);
      }
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchData(currentPage + 1);
    }
  };

  const followUser = async (userid) => {
    setAddingBuddy(true);
    setLoadingUserId(userid);
    try {
      const url = `${apiendpoint.path}/v1/users/follow`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      const postData = {
        follower_id: userid
      };
      const response = await axios.post(url, postData, { headers });
      if (response.status === 200) {
        fetchData()
        // Handle success, maybe update the UI
      } else {
        // Handle other status codes
      }
    } catch (error) {
      console.error('Error following user:', error);
    } finally {
        fetchData()
      setAddingBuddy(false);
      setLoadingUserId(null);
    }
  };

  const handleRefresh = () => {
    fetchData(1, true);  // Refresh and reset to page 1
  };

  const getTagColor = (percentile) => {
    if (percentile < 80) return '#4CAF50'; // Beginner - Green
    if (percentile < 90) return '#FFC107'; // Intermediate - Yellow
    if (percentile < 98) return '#2196F3'; // Master - Blue
    return '#F44336'; // Expert - Red
  };

  const getTag = (percentile) => {
    if (percentile < 80) return 'Beginner';
    if (percentile < 90) return 'Intermediate';
    if (percentile < 98) return 'Master';
    return 'Expert';
  };

  const RandomUserCard = ({ user }) => (
    <div className="random-user-card">
      <img src={user.display_pic} alt={user.display_name} className="random-user-image" />
      <div className="random-user-name">{user.display_name}</div>
      <div className="tag" style={{ color: getTagColor(user.percentile) }}>
        {getTag(user.percentile)}
      </div>
      <button
        className="react-button"
        onClick={() => !addingBuddy && followUser(user.user_id)}
        disabled={addingBuddy && loadingUserId === user.user_id}
      >
        {addingBuddy && loadingUserId === user.user_id ? (
          <div >loading</div>
        ) : (
          "Add Buddy"
        )}
      </button>
    </div>
  );

  const StudyBuddyItem = ({ buddy }) => (
    <tr  style={{marginBottom:5}}>
      <td><img src={buddy.display_pic} alt={buddy.display_name} style={{padding:2,marginLeft:-10}} className="buddy-image" /></td>
      <td className="name-cell">{buddy.display_name}</td>
      <td style={{ color: "green", fontWeight: "bold" }}>{buddy.user_score}</td>
      <td style={{ color: "red", fontWeight: "bold" }}>{buddy.buddy_score}</td>
      <td style={{ color: "orange", fontWeight: "bold" }}>{buddy.draw_score}</td>
      <td>
        {buddy.linkedin_url ? (
          <a href={buddy.linkedin_url} target="_blank" rel="noopener noreferrer">
            {/* <Lottie animationData={linkedinAnimation} style={{ height: 50, width: 50 }} /> */}
            Linkedin
          </a>
        ) : (
          "NA"
        )}
      </td>
    </tr>
  );

  return (
    <div className="study-buddies-screen">
      <div className="content-container">
        <h2 className="title">Suggested Study Buddies</h2>
        <div className="random-users-container">
          {loading ?  <div>
                <ShimmerLoader />
                <ShimmerLoader />
                <ShimmerLoader />
                <ShimmerLoader />
                <ShimmerLoader />
                <ShimmerLoader />
              </div>: randomUsers.map(user => (
            <RandomUserCard key={user.user_id} user={user} />
          ))}
        </div>
        <h2 className="title" style={{marginBottom:20}}>My Study Buddies Updates</h2>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th style={{paddingLeft:10}}>DP</th>
                <th className="name-cell">Name</th>
                <th>I Won</th>
                <th>I Lost</th>
                <th>Draw</th>
                <th>LinkedIn</th>
              </tr>
            </thead>
            <tbody>
              {loading ?  <div>
               loading...
              </div>:studyBuddies.map(buddy => (
                <StudyBuddyItem key={buddy.buddy_id} buddy={buddy} />
              ))}
            </tbody>
          </table>
          {studyBuddies.length === 0 && !loading && (
            <p className="no-buddies-text">You haven't added any study buddy yet.</p>
          )}
        </div>
        {loading ? (
          <p className="loading-text">Loading...</p>
        ) : (
          currentPage < totalPages && (
            <button className="next-page-button" onClick={handleNextPage}>
              Next Page
            </button>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token: state.auth.token
});

export default connect(mapStateToProps)(StudyBuddiesScreen);