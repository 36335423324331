// src/components/QuestionForm.js

import React, { useState, useCallback, useEffect } from 'react';
import { Form, Button, Row, Col, Card, Modal, Alert, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import axios from 'axios'
import './../index.css'
import { apiendpoint } from '../endpoint';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, connect, useSelector } from 'react-redux';

const majorAreas = ["Algebra", "Geometry", "Arithmetic", "Modern maths"];
const majorTopics = {
    "Algebra": [
      "Progressions",
      "Quadratic Equations",
      "Inequalities",
      "Number Series",
      "Basic Algebra",
      "Number System",
      "Rational Numbers",
      "Squares and Square Roots",
      "Cubes and Cube Roots",
      "Surds and Indices",
      "Basic Set Theory",
      "Functions and Indices"
    ],
    "Geometry": [
      "Mensuration 2D",
      "Mensuration 3D",
      "Geometry Fundamentals",
      "Circles",
      "Triangles",
      "Polygons",
      "Geometry",
      "Coordinate Geometry",
    ],
    "Arithmetic": [
      "Percentage",
      "Ratio and Proportion",
      "LCM and HCF",
      "Average",
      "Ratio to Percentage",
      "Simple Interest",
      "Compound Interest",
      "Profit and Loss",
      "Time and Distance",
      "Time and Work",
      "Partnership",
      "Work and Wages",
      "Upstream or Downstream",
      "Pipe and Cistern Problems",
      "Mixtures and Allegations"
    ],
    "Modern maths": [
      "Statistics",
      "Probability",
       "Permutations and Combinations",
       "Logarithms",
       
    ]
  };
  
const minorTopics = [
  "Progressions",
  "Coordinate Geometry",
  "Quadratic Equations",
  "Inequalities",
  "Geometry Fundamentals",
      "Circles",
      "Triangles",
      "Polygons",
    "Number Series",
    "Functions and Indices",
    "Number System",
    "Fundamental Arithmetical Operations",
    "Simplification & Approximation",
    "Percentage",
    "Decimal Fractions",
    "Ratio and Proportion",
    "LCM and HCF",
    "Rational Numbers",
    "Squares and Square Roots",
    "Cubes and Cube Roots",
    "Average",
    "Logarithms",
    "Surds and Indices",
    "Basic Set Theory",
    "Divisibility Rules",
    "Remainder Theorem",
    "Unitary Method",
    "Ratio to Percentage",
    "Simple Interest",
    "Compound Interest",
    "Profit and Loss",
    "Time and Distance",
    "Time and Work",
    "Partnership",
    "Work and Wages",
    "Upstream or Downstream",
    "Pipe and Cistern Problems",
    "Algebra",
    "Mixtures and Allegations",
    "Mensuration 2D",
    "Mensuration 3D",
    "Geometry",
    "Statistics",
    "Probability",
    "Permutations and Combinations"
  ];
  const categories = ["MCQ", "FIB", "TF"];
  const cognitiveProcesses = ["concept", "formula", "application", "critical thinking"];
  
  const ImageDropzone = ({ onDrop, accept }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept
    });

  
  
    return (
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the image here ...</p>
        ) : (
          <p>Drag 'n' drop an image here, or click to select one</p>
        )}
      </div>
    );
  };



const ImageUploader = ({ onUpload, currentImage }) => {
    const [isUploading, setIsUploading] = useState(false);
  
    const onDrop = useCallback(async (acceptedFiles) => {
      setIsUploading(true);
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('picture', file);
  
      try {
        const response = await axios.post(`${apiendpoint.path}/v1/users/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        onUpload(response.data.publicUrl);
      } catch (error) {
        console.error('Upload failed:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsUploading(false);
      }
    }, [onUpload]);
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });
  
    if (currentImage) {
      return (
        <div className="image-preview">
          <img src={currentImage} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }} />
          <Button variant="outline-secondary" size="sm" onClick={() => onUpload(null)} className="mt-2">
            Remove Image
          </Button>
        </div>
      );
    }
  
    return (
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {isUploading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Uploading...</span>
          </Spinner>
        ) : isDragActive ? (
          <p>Drop the image here ...</p>
        ) : (
          <p>Drag 'n' drop an image here, or click to select one</p>
        )}
      </div>
    );
  };
  
  
  
  const QuestionForm = ({isLoggedIn,token}) => {

    const navigate = useHistory();
    useEffect(()=>{
        if(isLoggedIn){
            if(navigate.push){
                navigate.replace('/secret')
            }
        }
      },[isLoggedIn])

  let  initialFormState = {
        majorArea: '',
        majorTopic: '',
        minorTopics: [], 
        category: '',
        difficultyLevel: 1,
        questionText: '',
        questionImage: null,
        options: [
            { text: '', image: null, trap: '' },
            { text: '', image: null, trap: '' },
            { text: '', image: null, trap: '' },
            { text: '', image: null, trap: '' }
        ],
        answerKey: 0,
        titaAnswer: '',
        solutionText: '',
        solutionImage: null,
        notesText: '',
        suggestedTime: '',
        cognitiveProcesses: [],
        traps: { calculation: false, formula: false, partial: false }
    }

      const [formData, setFormData] = useState({
          majorArea: '',
          majorTopic: '',
          minorTopics: [], 
          category: '',
          difficultyLevel: 1,
          questionText: '',
          questionImage: null,
          options: [
              { text: '', image: null, trap: '' },
              { text: '', image: null, trap: '' },
              { text: '', image: null, trap: '' },
              { text: '', image: null, trap: '' }
          ],
          answerKey: 0,
          titaAnswer: '',
          solutionText: '',
          solutionImage: null,
          notesText: '',
          suggestedTime: '',
          cognitiveProcesses: [],
          traps: { calculation: false, formula: false, partial: false }
      });

      const [showModal, setShowModal] = useState(false);
      const [errors, setErrors] = useState({});

  
  
      const validateForm = () => {
          const newErrors = {};
          if (!formData.majorArea) newErrors.majorArea = "Major Area is required";
          if (!formData.majorTopic) newErrors.majorTopic = "Major Topic is required";
          if (formData.minorTopics.length === 0) newErrors.minorTopics = "At least one Minor Topic is required";
          if (!formData.category) newErrors.category = "Category is required";
          if (!formData.questionText && !questionImage) newErrors.questionText = "Question Text is required";
          if (formData.category=="MCQ" && formData.options.some((option,index) => !option.text && !optionImages[index])) newErrors.options = "All options must have text";
          if (!formData.solutionText && !solutionImage) newErrors.solutionText = "Solution Text is required";
  
          setErrors(newErrors);
          console.log(newErrors)
          return Object.keys(newErrors).length === 0;
      };
  
      const [questionImageUrl, setQuestionImageUrl] = useState('');
      const [optionImageUrls, setOptionImageUrls] = useState(['', '', '', '']);
      const [solutionImageUrl, setSolutionImageUrl] = useState('');

      const [questionImage, setQuestionImage] = useState(null);
  const [optionImages, setOptionImages] = useState([null, null, null, null]);
  const [solutionImage, setSolutionImage] = useState(null);
    
      // ... (keep other existing states)


      const resetForm = () => {
        setFormData(initialFormState);
        setQuestionImageUrl('');
        setOptionImageUrls(['', '', '', '']);
        setSolutionImageUrl('');
        setSolutionImage('')
        setOptionImages('','','','')
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("validating")
          try {
            console.log("calling")
            console.log(formData.minor_topics)
            const formattedData = {
              major_area: formData.majorArea,
              major_topic: formData.majorTopic,
              minor_topics: {},
              category: formData.category,
              difficulty_level: parseInt(formData.difficultyLevel),
              question_text: formData.questionText,
              question_image: questionImage || "NA",
              option1_image: optionImages[0] || "NA",
              option2_image: optionImages[1] || "NA",
              option3_image: optionImages[2] || "NA",
              option4_image: optionImages[3] || "NA",
              solution_image: solutionImage || "NA",
              option1_text: formData.options[0].text,
             option1_trap: formData.options[0].trap,
              option2_text: formData.options[1].text,
              option2_trap: formData.options[1].trap,
              option3_text: formData.options[2].text,
              option3_trap: formData.options[2].trap,
              option4_text: formData.options[3].text,
              option4_trap: formData.options[3].trap,
              answer_key: formData.category === 'MCQ' ? parseInt(formData.answerKey) : -1,
              tita_answer: formData.category === 'FIB' || formData.category === 'TF' ? formData.titaAnswer : "NA",
              solution_text: formData.solutionText,
             notes_text: formData.notesText,
              calculation_trap: formData.traps.calculation,
              formula_application_trap: formData.traps.formula,
              partial_calculation_trap: formData.traps.partial,
              suggested_time: parseInt(formData.suggestedTime),
              cognitive_processes: formData.cognitiveProcesses,
            };
    
            if (Array.isArray(formData.minorTopics)) {
                formData.minorTopics.forEach((topic, index) => {
                  formattedData.minor_topics[`subtopic${index + 1}`] = topic;
                });
              } else if (typeof formData.minorTopics === 'object' && formData.minorTopics !== null) {
                formattedData.minor_topics = formData.minorTopics;
              }

            const response = await axios.post(apiendpoint.path+'/v1/admin/addquestion', formattedData);
            console.log('Question added successfully:', response.data);
          setErrors({message:"created question successfully"})
          resetForm()
            // Optionally, reset the form or show a success message
          } catch (error) {
            console.error('Error adding question:', error);
            setErrors(error.response.data)
          }
        }
      };
  
      const closeModal = () => {
          setShowModal(false);
      };
  
      const handleChange = (e) => {
        const { name, value, type, checked, options } = e.target;
        
        if (type === 'checkbox') {
          setFormData(prevState => ({
            ...prevState,
            [name]: checked
          }));
        } else if (name === 'minorTopics') {
          const selectedOptions = Array.from(options)
            .filter(option => option.selected)
            .map(option => option.value);
          
          setFormData(prevState => ({
            ...prevState,
            minorTopics: selectedOptions
          }));
        } else {
          setFormData(prevState => ({
            ...prevState,
            [name]: value
          }));
        }
      };

      
      const handleOptionChange = (index, field, value) => {
          const newOptions = [...formData.options];
          newOptions[index][field] = value;
          setFormData({ ...formData, options: newOptions });
      };
  
      const onQuestionImageDrop = useCallback(acceptedFiles => {
          setFormData(prevState => ({
              ...prevState,
              questionImage: acceptedFiles[0]
          }));
      }, []);
  
      const onOptionImageDrop = useCallback((acceptedFiles, index) => {
          const newOptions = [...formData.options];
          newOptions[index].image = acceptedFiles[0];
          setFormData(prevState => ({
              ...prevState,
              options: newOptions
          }));
      }, [formData.options]);
  
      const onSolutionImageDrop = useCallback(acceptedFiles => {
          setFormData(prevState => ({
              ...prevState,
              solutionImage: acceptedFiles[0]
          }));
      }, []);
  
     
  
      return (
        <>
          <Modal show={false} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Test Interface</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{formData.questionText}</h4>
                    {formData.questionImage && (
                        <img src={URL.createObjectURL(formData.questionImage)} alt="Question" style={{maxWidth: '100%', marginBottom: '1rem'}} />
                    )}
                    {formData.category === 'MCQ' && (
                        <Form>
                            {formData.options.map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    id={`option-${index}`}
                                    label={
                                        <>
                                            {option.text}
                                            {option.image && (
                                                <img src={URL.createObjectURL(option.image)} alt={`Option ${index + 1}`} style={{maxWidth: '100%', marginTop: '0.5rem'}} />
                                            )}
                                        </>
                                    }
                                    name="mcq-answer"
                                />
                            ))}
                        </Form>
                    )}
                    {formData.category === 'FIB' && (
                        <Form.Control type="text" placeholder="Your answer" />
                    )}
                    {formData.category === 'TF' && (
                        <Form>
                            <Form.Check type="radio" id="true" label="True" name="tf-answer" />
                            <Form.Check type="radio" id="false" label="False" name="tf-answer" />
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
          <Form onSubmit={handleSubmit} className="my-5" style={{padding:30}}>
              <Row className="mb-4">
                  <Col md={6}>
                      <Form.Group className="mb-3">
                          <Form.Label>Major Area</Form.Label>
                          <Form.Control as="select" name="majorArea" onChange={handleChange}>
                              <option value="">Select...</option>
                              {majorAreas.map(area => <option key={area} value={area}>{area}</option>)}
                          </Form.Control>
                      </Form.Group>
                  </Col>
                  <Col md={6}>
                      <Form.Group className="mb-3">
                          <Form.Label>Major Topic</Form.Label>
                          <Form.Control as="select" name="majorTopic" onChange={handleChange}>
                              <option value="">Select...</option>
                              {majorTopics[formData.majorArea]?.map(topic => <option key={topic} value={topic}>{topic}</option>)}
                          </Form.Control>
                      </Form.Group>
                  </Col>
              </Row>
  
              <Row className="mb-4">
                  <Col>
                      <Form.Group>
                          <Form.Label>Minor Topics</Form.Label>
                          <Form.Control as="select" multiple name="minorTopics" onChange={handleChange}>
                              {minorTopics.map(topic => <option key={topic} value={topic}>{topic}</option>)}
                          </Form.Control>
                      </Form.Group>
                  </Col>
              </Row>
  
              <Row className="mb-4">
                  <Col md={4}>
                      <Form.Group>
                          <Form.Label>Category</Form.Label>
                          <Form.Control as="select" name="category" onChange={handleChange}>
                              <option value="">Select...</option>
                              {categories.map(cat => <option key={cat} value={cat}>{cat}</option>)}
                          </Form.Control>
                      </Form.Group>
                  </Col>
                  <Col md={4}>
                      <Form.Group>
                          <Form.Label>Difficulty Level</Form.Label>
                          <Form.Control type="number" name="difficultyLevel" min="1" max="10" onChange={handleChange} />
                      </Form.Group>
                  </Col>
                  <Col md={4}>
                      <Form.Group>
                          <Form.Label>Suggested Time (in seconds)</Form.Label>
                          <Form.Control type="number" name="suggestedTime" onChange={handleChange} />
                      </Form.Group>
                  </Col>
              </Row>
  
              <Card className="mb-4">
                  <Card.Body>
                      <Form.Group className="mb-3">
                          <Form.Label>Question Text</Form.Label>
                          <Form.Control as="textarea" name="questionText" onChange={handleChange} rows={3} />
                      </Form.Group>
                      <Form.Group>
                      <Form.Label>Question Image</Form.Label>
            <ImageUploader onUpload={setQuestionImage} currentImage={questionImage} />
          </Form.Group>
                  </Card.Body>
              </Card>
  
              <Row className="mb-4">
        {formData.options.map((option, index) => (
          <Col md={6} key={index} className="mb-3">
            <Card>
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Option {index + 1}</Form.Label>
                  <Form.Control 
                    type="text" 
                    value={option.text} 
                    onChange={(e) => handleOptionChange(index, 'text', e.target.value)} 
                    placeholder="Option text" 
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Option Image</Form.Label>
                  <ImageUploader 
                    onUpload={(url) => {
                      const newImages = [...optionImages];
                      newImages[index] = url;
                      setOptionImages(newImages);
                    }} 
                    currentImage={optionImages[index]} 
                  />
                </Form.Group>
                <Form.Group>
                                      <Form.Label>Trap</Form.Label>
                                      <Form.Control as="select" onChange={(e) => handleOptionChange(index, 'trap', e.target.value)}>
                                          <option value="">Select Trap...</option>
                                          <option value="calculation">Calculation Trap</option>
                                          <option value="formula">Formula Application Trap</option>
                                          <option value="partial">Partial Calculation Trap</option>
                                      </Form.Control>
                                  </Form.Group>
                {/* ... (keep trap selection) */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
  
              <Row className="mb-4">
                  <Col md={6}>
                      <Form.Group>
                          <Form.Label>Answer Key (0-3)</Form.Label>
                          <Form.Control type="number" name="answerKey" min="0" max="3" onChange={handleChange} />
                      </Form.Group>
                  </Col>
                  <Col md={6}>
                      <Form.Group>
                          <Form.Label>TITA Answer</Form.Label>
                          <Form.Control type="text" name="titaAnswer" onChange={handleChange} />
                      </Form.Group>
                  </Col>
              </Row>
  
              <Card className="mb-4">
        <Card.Body>
          <Form.Group className="mb-3">
            <Form.Label>Solution Text</Form.Label>
            <Form.Control as="textarea" name="solutionText" onChange={handleChange} rows={3} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Solution Image</Form.Label>
            <ImageUploader onUpload={setSolutionImage} currentImage={solutionImage} />
          </Form.Group>
        </Card.Body>
      </Card>
  
              <Form.Group className="mb-4">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" name="notesText" onChange={handleChange} rows={3} />
              </Form.Group>
  
              <Form.Group className="mb-4">
                <Form.Label>Cognitive Processes</Form.Label>
                <div className="d-flex flex-wrap" style={{flexDirection:"row"}}>
                    {cognitiveProcesses.map(process => (
                        <Form.Check 
                            key={process} 
                            type="checkbox" 
                            id={`cognitive-${process}`}
                            label={process} 
                            value={process} 
                            className="me-3 mb-2"
                            onChange={(e) => {
                                const newProcesses = e.target.checked
                                    ? [...formData.cognitiveProcesses, process]
                                    : formData.cognitiveProcesses.filter(p => p !== process);
                                setFormData({ ...formData, cognitiveProcesses: newProcesses });
                            }} 
                        />
                    ))}
                </div>
            </Form.Group>
              <Form.Group className="mb-4">
                  <Form.Label>Traps</Form.Label>
                  {['calculation', 'formula', 'partial'].map(trap => (
                      <Form.Check 
                          key={trap} 
                          type="radio" 
                          label={trap.charAt(0).toUpperCase() + trap.slice(1)} 
                          name="traps" 
                          checked={formData.traps[trap]} 
                          onChange={() => setFormData(prev => ({
                              ...prev,
                              traps: { calculation: trap === 'calculation', formula: trap === 'formula', partial: trap === 'partial' }
                          }))} 
                      />
                  ))}
              </Form.Group>


              {Object.keys(errors).length > 0 && (
                    <div  variant="danger">
                        Please correct the following errors:
                        <ul>
                            {Object.values(errors).map((error, index) => {
                                return <li color={error=="created question successfully"?"green":"red"} key={index}>{error}</li>
                            })}
                        </ul>
                    </div>
                )}

  
              <Button variant="primary" type="submit">Create Question</Button>
          </Form>
          
          </>
      );
  };
  
const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    token:state.auth.token
});

export default connect(mapStateToProps)(QuestionForm);